<template>
    <div>
        <div class="auth-bg-img"></div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.auth-bg-img {
    background-image: url('~@/assets/img/login.png');
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    height: 650px;
    max-width: 100%;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    @media (max-width: 575.98px) {
        height: 300px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 15px;
    }
    @media (max-width: 767.98px) {
        height: 350px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 15px;
    }
    @media (min-width: 767.98px) {
        border-bottom-left-radius: 0px;
        border-top-right-radius: 15px;
    }
    @media (min-width: 991.98px) {
        border-top-right-radius: 0px;
        border-bottom-left-radius: 15px;
    }
    @media (min-width: 1199.98px) {
        height: 730px;
        background-position: center;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 15px;
    }
}
</style>
