<template>
    <div class="">
        <!-- <div class="back">
            <span @click="$router.go(-1)" class="btn-close"></span>
        </div> -->

        <h3 class="mb-4 fw-bold text-center">{{ header }}</h3>
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        header: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.back {
    display: flex;
    justify-content: flex-end;
    // padding: 2rem 3.5rem;

    span {
        cursor: pointer;
    }
}

// .auth-form {
//     height: auto;

//     .auth-form-wrapper {
//         label {
//             font-size: 20px;
//         }
//         input,
//         .input-group-text {
//             border: none;
//             background: #f0f0f0;
//         }
//         .input-group-text {
//             cursor: pointer;
//         }
//     }
// }
</style>
