<template>
    <div
        class="d-flex flex-column justify-content-center container p-lg-0"
        style="margin:3rem auto;"
    >
        <div class="d-flex border register" style="border-radius: 15px;">
            <bg-auth style="width: 65%;" class="bg-auth" />
            <div
                class="d-flex flex-column justify-content-between auth-form"
                style="width:35%;"
            >
                <auth-form header="Log In" class="auth-form">
                    <div class="mb-2 mx-2">
                        <div class="input-group">
                            <span class="input-group-text input-icon">
                                <mail-icon />
                            </span>
                            <input
                                type="text"
                                class="form-control"
                                v-model="auth.username"
                                placeholder="E-mail (or) Phone"
                                style="border-left-color:white"
                            />
                        </div>

                        <div
                            class="error invalid-feedback d-block"
                            v-if="
                                submitStatus == 'ERROR' &&
                                    !$v.auth.username.required
                            "
                        >
                            Email or Phone Number is required.
                        </div>
                        <div
                            class="error invalid-feedback d-block"
                            v-if="isUserNameError"
                        >
                            Email or Phone Number should be right Format.
                        </div>
                    </div>

                    <div class="mb-2 mx-2">
                        <div class="input-group">
                            <span class="input-group-text input-icon">
                                <key-icon class="key-icon" />
                            </span>
                            <input
                                :type="passwordType"
                                class="form-control"
                                v-model="auth.password"
                                placeholder="Set Password"
                                style="border-left-color:white"
                            />
                            <span
                                class="input-group-text"
                                id="basic-addon1"
                                @click="handleShowPwd"
                                style="border-top: 1px solid #0000000d !important;border-right: 1px solid #0000000d !important;border-bottom: 1px solid #0000000d !important;border-left: 0.01px solid white !important;"
                            >
                                <font-awesome-icon
                                    v-if="showPassword"
                                    icon="eye"
                                />
                                <font-awesome-icon v-else icon="eye-slash" />
                            </span>
                            <div
                                class="error invalid-feedback d-block"
                                v-if="
                                    submitStatus == 'ERROR' &&
                                        !$v.auth.password.required
                                "
                            >
                                Password is required.
                            </div>
                        </div>
                    </div>

                    <div
                        class="error invalid-feedback d-block mx-2"
                        v-if="submitStatus == 'ERROR' && authError"
                    >
                        {{ authError }}
                    </div>

                    <div class="mb-2 mx-2">
                        <input
                            type="checkbox"
                            id="rememberMe"
                            v-model="rememberMe"
                        />
                        <label for="rememberMe" class="ms-2">Remember Me</label>
                    </div>
                    <!-- <div
                    class="error invalid-feedback d-block"
                    v-if="submitStatus == 'ERROR' && authPhoneError"
                >
                    {{ authPhoneError }}
                </div> -->
                    <div class="text-center mx-2">
                        <button
                            v-if="!isLoading"
                            @click="userLogin"
                            class="w-100 login-button"
                        >
                            <span style="font-size:1rem">Login</span>
                            <font-awesome-icon
                                class="ms-2"
                                :icon="['fas', 'chevron-right']"
                            />
                        </button>
                        <button
                            v-else
                            class="btn btn-primary loading-btn w-100"
                            type="button"
                            disabled
                        >
                            <span
                                class="spinner-grow spinner-grow-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Loading...
                        </button>
                    </div>

                    <div class="text-center">
                        --or--
                    </div>

                    <!-- <div class="text-center mx-2">
                        <button
                            v-if="!isFacebookLoading"
                            @click="loginWithFb"
                            class="w-100 fb-button"
                        >
                            <font-awesome-icon
                                :icon="['fab', 'facebook-f']"
                                class="me-2"
                            />
                            <span style="font-size:1rem"
                                >Login with Facebook</span
                            >
                        </button>
                        <div class="px-5" v-else>
                            <span
                                class="spinner-grow spinner-grow-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Loading...
                        </div>
                    </div> -->

                    <div class="text-center mt-2 mx-2">
                        <button
                            @click="handleClickSignIn"
                            :disabled="!isInit"
                            class="w-100 google-button"
                        >
                            <font-awesome-icon
                                :icon="['fab', 'google']"
                                class="me-2"
                            />
                            <span style="font-size:1rem"
                                >Login with Google</span
                            >
                        </button>
                        <div v-if="isGoogleLoading" class="px-5">
                            <span
                                class="spinner-grow spinner-grow-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Loading...
                        </div>
                    </div>
                </auth-form>
                <div
                    class="d-flex justify-content-center justify-content-between w-100 border-top py-2"
                >
                    <router-link
                        class="text-dark mx-2"
                        :to="{ name: 'forgetPassword' }"
                        style="text-decoration:none"
                        >Reset Password</router-link
                    >
                    <p
                        class="text-dark mx-2 mb-0"
                        @click="goToRegister"
                        style="text-decoration:none;cursor:pointer"
                    >
                        Create Account
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AddToWishlist from '@/mixins/AddToWishlist';

import BgAuth from '../../components/auth/BgAuth';
import AuthForm from '../../components/auth/AuthForm';
// import Button from '../../components/reusable/Button';

// mutations
import UserLogin from '../../graphql/mutations/auth/UserLogin.gql';
import SocialLogin from '../../graphql/mutations/auth/socialLogin.gql';

// vuelidate
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import encryptDecrypt from '@/mixins/PromotionEncryptDecrypt';

// apollo
import { onLogin } from '../../graphql/vue-apollo';
// import GetUser from '@/graphql/queries/auth/GetUser.gql';

// fb config
import { initFbsdk } from '../../config/fb';

export default {
    mixins: [validationMixin, AddToWishlist, encryptDecrypt],

    components: {
        'bg-auth': BgAuth,
        'auth-form': AuthForm,
        // .login-button': Button,
    },

    data() {
        return {
            auth: {
                username: '',
                password: '',
            },
            passwordType: 'password',
            showPassword: false,
            submitStatus: null,
            isLoading: false,
            isFacebookLoading: false,
            isGoogleLoading: false,
            isSocialLoading: false,
            authError: '',
            isUserNameError: false,
            // isSignIn: false,
            isInit: false,
            rememberMe: false,
        };
    },
    computed: {
        ...mapGetters({
            carts: 'cart/getCarts',
        }),
    },
    mounted() {
        const storedRememberMe = localStorage.getItem('rememberMe');
        if (storedRememberMe) {
            this.auth.username = JSON.parse(storedRememberMe).username;
            this.auth.password = this.decryptAES(
                JSON.parse(storedRememberMe).password,
                'pss',
            );
            this.rememberMe = true;
        }

        initFbsdk();
        let that = this;
        let checkGauthLoad = setInterval(function() {
            that.isInit = that.$gAuth.isInit;
            // that.isSignIn = that.$gAuth.isAuthorized;
            if (that.isInit) clearInterval(checkGauthLoad);
        }, 1000);
    },

    validations: {
        auth: {
            username: {
                required,
            },
            password: {
                required,
            },
        },
    },

    methods: {
        storedUser(value) {
            // Watch for changes in the rememberMe value and store it in local storage
            if (value) {
                localStorage.setItem(
                    'rememberMe',
                    JSON.stringify({
                        username: this.auth.username,
                        password: this.encryptAES(this.auth.password, 'pss'),
                    }),
                );
            } else {
                localStorage.setItem('rememberMe', null);
            }
        },
        async handleClickSignIn() {
            this.isGoogleLoading = true;
            try {
                const googleUser = await this.$gAuth.signIn();
                console.log('user', googleUser);
                if (googleUser) {
                    this.callSocialLoginApi(
                        googleUser.getAuthResponse().access_token,
                        'google',
                    );
                }
            } catch (error) {
                // On fail do something
                console.error(error);
                this.isGoogleLoading = false;
                return null;
            }
            this.isGoogleLoading = false;
        },

        async handleClickSignOut() {
            try {
                await this.$gAuth.signOut();
                // this.isSignIn = this.$gAuth.isAuthorized;
            } catch (error) {
                // On fail do something
            }
        },
        handleShowPwd() {
            this.showPassword = !this.showPassword;
            if (this.showPassword) {
                this.passwordType = 'string';
            } else {
                this.passwordType = 'password';
            }
        },

        goToRegister() {
            this.$router.push({
                name: 'Register',
            });
        },

        userLogin() {
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR';
            } else {
                const regex = new RegExp(
                    // eslint-disable-next-line no-useless-escape
                    '^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|(^[0-9]{11,15})|(^([+959])+([0-9]{9,15}))+$',
                );
                if (regex.test(this.auth.username)) {
                    this.isLoading = true;
                    this.$apollo
                        .mutate({
                            mutation: UserLogin,
                            variables: {
                                username: this.auth.username,
                                password: this.auth.password,
                            },
                        })
                        .then(response => {
                            this.submitStatus = 'OK';
                            onLogin(
                                this.$apollo.provider.defaultClient,
                                response.data.login.access_token,
                            );
                            this.$store.commit(
                                'auth/ADD_TOKEN',
                                response.data.login.access_token,
                            );
                            this.$store.commit(
                                'auth/ADD_REFRESH_TOKEN',
                                response.data.login.refresh_token,
                            );
                            // this.$apollo
                            //     .query({
                            //         query: GetUser,
                            //         fetchPolicy: 'no-cache',
                            //     })
                            //     .then(response => {
                            //         console.log('here');
                            //         this.$store.commit(
                            //             'auth/ADD_USER',
                            //             response.data,
                            //         );
                            //         this.getWishlists();
                            //     })
                            //     .catch(error => {
                            //         console.log('there');
                            //         console.log(error);
                            //     });
                            this.isLoading = false;
                            if (this.$route.query.slug === 'shoppingBag') {
                                this.$router.push({
                                    name: 'ShoppingBag',
                                });
                            } else if (this.$route.query.slug === 'redeem') {
                                this.$router.go(-1);
                            } else if (this.$route.query.slug === 'getPoints') {
                                this.$router.go(-1);
                            } else if (
                                (this.$route.query.slug === 'wishList' &&
                                    this.$route.query.id) ||
                                this.$route.query.slug === 'wishlist'
                            ) {
                                if (this.$route.query.id) {
                                    this.addToWish(this.$route.query.id);
                                }
                                this.$router.push({
                                    name: 'WishList',
                                });
                            } else
                                this.$router.push({
                                    name: 'Home',
                                });
                        })
                        .catch(errors => {
                            console.log(errors);
                            this.submitStatus = 'ERROR';
                            this.isLoading = false;
                            this.authError =
                                errors.graphQLErrors[0].extensions.reason;
                        });
                    this.storedUser(true);
                } else {
                    this.isUserNameError = true;
                }
            }
        },

        loginWithFb() {
            this.isSocialLoading = true;
            initFbsdk();
            window.FB.login(response => {
                console.log('fb response', response);
                if (response.authResponse) {
                    this.callSocialLoginApi(
                        response.authResponse.accessToken,
                        'facebook',
                    );
                }
            }, this.params);
        },

        callSocialLoginApi(token, provider) {
            this.$apollo
                .mutate({
                    mutation: SocialLogin,

                    variables: {
                        provider: provider,
                        token: token,
                    },
                })
                .then(response => {
                    // console.log(response);
                    if (response.data.socialLogin.isUpdate) {
                        onLogin(
                            this.$apollo.provider.defaultClient,
                            response.data.socialLogin.access_token,
                        );
                        this.$store.commit(
                            'auth/ADD_TOKEN',
                            response.data.socialLogin.access_token,
                        );
                        this.$store.commit(
                            'auth/ADD_REFRESH_TOKEN',
                            response.data.socialLogin.refresh_token,
                        );
                        this.isSocialLoading = false;
                        this.$router.push({
                            name: 'ProfileEdit',
                        });
                    } else {
                        this.$store.commit(
                            'auth/ADD_SOCIAL_USER',
                            response.data.socialLogin.user,
                        );
                        this.isSocialLoading = false;
                        this.goToRegister();
                    }
                })
                .catch(error => {
                    this.isSocialLoading = false;
                    console.log(error);
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.login-button {
    background-color: #49bb09;
    border-radius: 10px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    width: max-content;
    padding: 0.5rem 1rem;
    transition: all 0.5s ease;
    border: none;
    &:hover {
        opacity: 0.8;
    }
}
.fb-button {
    background-color: #1877f2;
    border-radius: 10px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    width: max-content;
    padding: 0.5rem 1rem;
    transition: all 0.5s ease;
    border: none;
    &:hover {
        opacity: 0.8;
    }
}
.google-button {
    background-color: transparent;
    border: 1px solid #0000000d !important;
    border-radius: 10px;
    color: #333;
    font-size: 14px;
    font-weight: 500;
    width: max-content;
    padding: 0.5rem 1rem;
    transition: all 0.5s ease;
    border: none;
    &:hover {
        opacity: 0.8;
    }
}
.auth-form {
    padding: 1rem 0;
    .key-icon {
        rotate: 180deg;
    }
    .input-icon {
        background: white;
        // border-right-color: 1px solid white;
        border: 1px solid #0000000d;
        padding-right: 0;
    }
}
.input-group-text {
    border-radius: 10px;
}
.form-control {
    border: 1px solid #0000000d;
    border-radius: 10px;
}
::placeholder {
    color: #111111;
    opacity: 0.5;
}
@media (min-width: 280px) and (max-width: 991.98px) {
    .register {
        flex-direction: column;
    }
    .bg-auth {
        width: 100% !important;
    }
    .auth-form {
        width: 100% !important;
    }
}
</style>
